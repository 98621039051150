
export class Color {
    name: string = "red";
    value: string = "red";

    constructor(name: string, value: string) {
        this.name = name;
        this.value = value;
    }
}
