export class Stripe {
    fill: string = "red";
    xPos: number = 0;
    width: number = 1;

    constructor(fill: string, xPos: number, width: number) {
        this.fill = fill;
        this.width = width;
        this.xPos = xPos;
    }
}
